import React from 'react';
import { IconContext } from 'react-icons';
import { FaArrowAltCircleRight, FaArrowAltCircleDown } from 'react-icons/fa'
import { main, top, title, subTitle, step } from './ProcessBanner.module.css';
import { useTranslation } from "react-i18next";
import useMediaQuery from '../hooks/useMediaQuery';

export default () => {
    const { t } = useTranslation();

    const isDesktop = useMediaQuery('(min-width: 769px)');

    const copy = t('home.movingProcess', { returnObjects: true });

    return (
        <div className={main}>
            <div className={top}>
                <h4 className={title}>{copy.title}</h4>
                <h1 className={subTitle}>{copy.subTitle}</h1>
            </div>
            <div>
                <IconContext.Provider value={{ size: '3em'}}>
                    {copy.steps.map((item, index) => (
                        <div key={index} className={step}>
                            <div>
                                <item.icon />
                                <span>{item.label}</span>
                            </div>
                            <div>
                                {index !== copy.steps.length - 1 && (isDesktop ? (
                                    <span><FaArrowAltCircleRight size={'1.5em'}/></span>
                                ) : (
                                    <span><FaArrowAltCircleDown size={'1.5em'}/></span>
                                ))}
                            </div>
                        </div>
                    ))}
                </IconContext.Provider>
            </div>
        </div>
    );
}
