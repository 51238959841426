import { useEffect, useState } from 'react';

export default (variable) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        const value = getComputedStyle(document.documentElement).getPropertyValue(variable);

        setValue(value);
    }, [variable])

    return value;
}
