import React from "react"
import { Helmet } from 'react-helmet'
import { movingTips, customerReviews, faq } from './index.module.css';
import { useTranslation } from "react-i18next";
import Layout from '../components/Layout';
import Section from '../components/Section';
import Hero from '../components/Hero';
import WhyUs from '../components/WhyUs';
import TabbedSection from '../components/TabbedSection';
import ProcessBanner from '../components/ProcessBanner';
import MovingTips from '../components/MovingTips';
import CustomerReviews from '../components/CustomerReviews';
import Faq from '../components/Faq';

import { findImageData } from '../utils/tools';
import useImages from '../hooks/useImages';
import BackgroundImage from '../components/BackgroundImage';

export default () => {
    const { t } = useTranslation();

    const images = useImages();

    return (
        <Layout>
            <Helmet>
                <title>Flash Moves Transport Solutions</title>
            </Helmet>
            <Section>
                <Hero />
            </Section>
            <Section>
                <WhyUs />
            </Section>
            <TabbedSection />
            <Section>
                <ProcessBanner />
            </Section>
            <Section>
                <div className={movingTips}>
                    <MovingTips />
                </div>
            </Section>
            <Section>
                <BackgroundImage
                    opacity={0.6}
                    imageData={findImageData(images, t('home.customerReviews.image'))}
                />
                <div className={customerReviews}>
                    <div data-top>
                        <h1>{t('home.customerReviews.headline')}</h1>
                    </div>
                    <CustomerReviews />
                </div>
            </Section>
            <Section>
                <div className={faq}>
                    <div data-top>
                        <h1>{t('home.faq.title')}</h1>
                        <h3>{t('home.faq.subtitle')}</h3>
                    </div>
                    <div data-bottom>
                        <div data-left>
                            <Faq />
                        </div>
                        <div data-right>
                            <video controls muted loop>
                                <source src="https://storage.googleapis.com/flashmoves/videos/WhatsApp%20Video%202020-05-02%20at%2021.43.07.mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </Section>
        </Layout>
    );
}
