import React, { useEffect, useRef } from "react"
import lottie from 'lottie-web';
import { Link } from 'gatsby';
import { useTranslation } from "react-i18next";
import { sectionContent, whyUsLogo } from './WhyUs.module.css';
import Button from './Button';

export default () => {
    const { t } = useTranslation();

    const contentRef = useRef();

    useEffect(() => {
        const scrollHandler = (e) => {
            const dimensions = contentRef.current.getBoundingClientRect();

            if (animation && dimensions.top <= 200) {
                animation.play()
                window.removeEventListener('scroll', scrollHandler);
            }
        };

        const animation = lottie.loadAnimation({
            container: document.querySelector(`.${whyUsLogo}`),
            path: 'animation.json',
            renderer: 'svg',
            loop: false,
            autoplay: false
        });

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            animation.destroy();
        }
    }, []);

    return (
        <div ref={contentRef} className={sectionContent}>
            <div className={whyUsLogo}></div>
            <div>
                <h1>{t('home.whyUs.headline')}</h1>
                <p>{t('home.whyUs.paragraph')}</p>
                <Link to={t('home.whyUs.callToAction.url')}>
                    <Button>{t('home.whyUs.callToAction.label')}</Button>
                </Link>
            </div>
        </div>
    );
}
