import React from "react"
import { useTranslation } from "react-i18next";
import { Link } from 'gatsby';
import { main, heroPresentation, form } from './Hero.module.css';
import useImages from "../hooks/useImages";
import { findImageData } from "../utils/tools";
import BackgroundImage from '../components/BackgroundImage';
import GetQuoteForm from '../components/GetQuoteForm';
import Button from '../components/Button';

export default () => {
    const { t } = useTranslation();

    const images = useImages();

    const copy = t('home.hero', { returnObjects: true });

    return (
        <>
            <BackgroundImage
                opacity={0.3}
                imageData={findImageData(images, copy.image)}
            />
            <div className={main}>
                <div>
                    <div className={heroPresentation}>
                        <h1>{copy.headline}</h1>
                        <p>{copy.paragraph}</p>
                        <Link to={copy.callToAction.url}>
                            <Button>{copy.callToAction.label}</Button>
                        </Link>
                    </div>
                </div>
                <div>
                    <div className={form}>
                        <h2>{t('home.getQuoteForm.title')}</h2>
                        <br />
                        <GetQuoteForm />
                    </div>
                </div>
            </div>
        </>
    );
}
