import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { findImageData } from '../utils/tools';
import useCSSVariable from '../hooks/useCSSVariable';
import useImages from '../hooks/useImages';
import useMediaQuery from '../hooks/useMediaQuery';
import { tabs, tab, tabText, service, fixed, show, hide } from './TabbedSection.module.css';
import Service from './Service';

export default () => {
    const hiddenHeaderHeightCSSVariable = useCSSVariable('--hidden-header-height');

    const headerHeightCSSVariable = useCSSVariable('--header-height');

    const tabsHeightCSSVariable = useCSSVariable('--tabs-height');

    const isDesktop = useMediaQuery('(min-width: 769px)');

    const tabsRef = useRef(null);

    const { t } = useTranslation();

    const images = useImages();

    const sections = t(
        `home.tabbedNavigation`,
        { returnObjects: true }
    ).map((section, index) => {
        return {
            ...section,
            ref: useRef(null),
            imageData: findImageData(images, section.image),
        };
    });

    const [state, setState] = useState({
        displayAnimation: null,
        activeTab: null,
        isFixed: false
    });

    const getFixedState = (
        tabsBoundingClientRect,
        sectionBoundingClientRectList,
        headerHeight,
        tabsHeight
    ) => {
        const isFixed = (
            tabsBoundingClientRect.top <= headerHeight
            && sectionBoundingClientRectList[0].top <= (tabsHeight + headerHeight)
        );
        return (
            isFixed
            && !(
                tabsBoundingClientRect.top === headerHeight
                && sectionBoundingClientRectList[0].top > (tabsHeight + headerHeight)
            )
        );
    }

    const getDisplayAnimation = (tabsAreFixed, previousAnimation, sectionBoundingClientRectList) => {
        const lastSection = sectionBoundingClientRectList[sectionBoundingClientRectList.length - 1];
        const shouldShow = lastSection.bottom >= lastSection.height * 0.4
        const hidden = previousAnimation === 'hide';

        if (tabsAreFixed) {
            if (shouldShow && hidden) {
                return 'show';
            }
            if (!shouldShow && !hidden) {
                return 'hide';
            }
            return previousAnimation;
        } else {
            return null;
        }
    }

    const getActiveTab = (sectionBoundingClientRectList, triggerHeight) => {
        return sectionBoundingClientRectList.reduce((prev, curr, index, list) => {
            if (typeof prev === 'number') return prev;

            if (!prev) return curr;

            if (prev.top <= triggerHeight) {
                if (curr.top >= triggerHeight) {
                    return index - 1;
                } else {
                    if (index === list.length - 1) {
                        return index;
                    } else {
                        return curr;
                    }
                }
            }

            return null;
        });
    }

    const getNumberValue = CSSValue => parseFloat(CSSValue.match(/\d+/)[0]);

    const scrollTo = (item) => {
        const top = item.ref.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top, behavior: 'smooth' });
    };

    useEffect(() => {
        const scrollHandler = (e) => {
            const hiddenHeaderHeight = headerHeightCSSVariable ? getNumberValue(hiddenHeaderHeightCSSVariable) : 0;
            const headerHeight = headerHeightCSSVariable ? getNumberValue(headerHeightCSSVariable) : 0;
            const tabsHeight = tabsHeightCSSVariable ? getNumberValue(tabsHeightCSSVariable) : 0;

            setState(prevState => ({
                ...prevState,
                isFixed: getFixedState(
                    tabsRef.current.getBoundingClientRect(),
                    sections.map(item => item.ref.current.getBoundingClientRect()),
                    headerHeight - hiddenHeaderHeight,
                    tabsHeight
                ),
                displayAnimation: getDisplayAnimation(
                    prevState.isFixed,
                    prevState.displayAnimation,
                    sections.map(item => item.ref.current.getBoundingClientRect())
                ),
                activeTab: getActiveTab(
                    sections.map(item => item.ref.current.getBoundingClientRect()),
                    tabsHeight + (headerHeight - hiddenHeaderHeight)
                ),
            }));
        }

        if (isDesktop) {
            window.addEventListener('scroll', scrollHandler);
        }

        return () => window.removeEventListener('scroll', scrollHandler);
    }, [isDesktop, sections, hiddenHeaderHeightCSSVariable, headerHeightCSSVariable, tabsHeightCSSVariable]);

    const makeTabLabel = (title) => {
        return title.split('\n').map((line, index) => {
            return <span style={{ display: 'block' }} key={index}>{line}</span>;
        });
    };

    return (
        <>
            <div
                ref={tabsRef}
                className={`
                    ${tabs}
                    ${state.isFixed ? fixed : ''}
                    ${state.displayAnimation === 'show' ? show : ''}
                    ${state.displayAnimation === 'hide' ? hide : ''}
                `}
            >
                {sections.map((item, index) => (
                    <div
                        key={index}
                        className={tab}
                        role="button"
                        tabIndex="0"
                        onClick={() => scrollTo(item)}
                        onKeyPress={() => scrollTo(item)}
                        style={{
                            borderColor: state.activeTab === index ? `var(--red)` : undefined,
                            color: state.activeTab === index ? 'var(--red)' : undefined,
                        }}>
                        <item.icon />
                        <div className={tabText}>{makeTabLabel(item.title)}</div>
                    </div>
                ))}
            </div>
            {sections.map((item, index) => (
                <section
                    className={service}
                    key={index}
                    ref={item.ref}
                    style={{
                        marginTop: state.isFixed && index === 0 && 'var(--tabs-height)'
                    }}
                >
                    <Service data={item} />
                </section>
            ))}
        </>
    );
}
